var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "predecessor-list",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "predecessor-label" },
        [
          _c("div", { staticClass: "title mb-2" }, [
            _vm._v(_vm._s(_vm.$t("task_predecessor.title_predecessors"))),
          ]),
          !_vm.readOnly
            ? [
                _c(
                  "b-alert",
                  {
                    staticClass: "mb-0 alert-box",
                    attrs: {
                      dismissible: "",
                      fade: "",
                      show: _vm.showError,
                      variant: "success",
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "check"] },
                    }),
                    _vm._v("   " + _vm._s(_vm.alertMsg) + " "),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "predecessor-toolbar border" },
                  [
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          id: `BTN_ADD_${_vm.id}`,
                          disabled: _vm.readOnly,
                        },
                        on: { click: _vm.predecessorAdd },
                      },
                      [
                        _c("font-awesome-icon", {
                          style: { color: "var(--grid-toolbar-button)" },
                          attrs: { icon: ["far", "plus"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: `BTN_ADD_${_vm.id}`,
                          triggers: "hover",
                          placement: "bottom",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                    ),
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          disabled: _vm.disableEdit || _vm.readOnly,
                          id: `BTN_EDIT_${_vm.id}`,
                        },
                        on: { click: _vm.predecessorEdit },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "pen-to-square"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: `BTN_EDIT_${_vm.id}`,
                          triggers: "hover",
                          placement: "top",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                    ),
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          disabled: _vm.disableDelete || _vm.readOnly,
                          id: `BTN_DELETE_${_vm.id}`,
                        },
                        on: { click: _vm.predecessorDelete },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "trash-can"] },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: `BTN_DELETE_${_vm.id}`,
                          triggers: "hover",
                          placement: "top",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham predecessor-grid-height",
        staticStyle: { width: "100%" },
        attrs: {
          id: "predecessorGrid",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressContextMenu: "",
          suppressMultiSort: "",
          suppressRowClickSelection: _vm.readOnly,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("task_predecessor.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    `task_predecessor.confirmation.delete${
                      _vm.selected.length > 1 ? "_plural" : ""
                    }`
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _vm.predecessorShow
        ? _c("PredecessorModal", {
            attrs: {
              predecessor: _vm.predecessor,
              list: _vm.predecessors,
              readOnly: _vm.predecessorReadOnly,
              show: _vm.predecessorShow,
              title: _vm.predecessorTitle,
              projectId: _vm.projectId,
              taskId: _vm.taskId,
              taskName: _vm.taskName,
              isTemplate: _vm.isTemplate,
            },
            on: {
              "update:show": function ($event) {
                _vm.predecessorShow = $event
              },
              success: _vm.predecessorSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }