<template>
  <div :id="id" class="predecessor-list" style="width: 100%">
    <div class="predecessor-label">
      <div class="title mb-2">{{ $t('task_predecessor.title_predecessors') }}</div>
      <template v-if="!readOnly">
        <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert" class="mb-0 alert-box">
          <font-awesome-icon :icon="['far', 'check']"/>
          &nbsp;&nbsp;{{ alertMsg }}
        </b-alert>
        <div class="predecessor-toolbar border">
          <b-btn :id="`BTN_ADD_${id}`" :disabled="readOnly" @click="predecessorAdd"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="bottom">
            {{ $t('button.add') }}
          </b-popover>
          <b-btn :disabled="disableEdit || readOnly" :id="`BTN_EDIT_${id}`" @click="predecessorEdit"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
          <b-btn :disabled="disableDelete || readOnly" :id="`BTN_DELETE_${id}`" @click="predecessorDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </div>
      </template>
    </div>
    

    <ag-grid-vue style="width: 100%;" class="ag-theme-balham predecessor-grid-height" id="predecessorGrid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :rowData="rowData"
          :rowSelection="multiple? 'multiple':'single'"
          rowMultiSelectWithClick
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressContextMenu
          suppressMultiSort
          :suppressRowClickSelection="readOnly"
          >
    </ag-grid-vue>

    <b-modal :title="$t('task_predecessor.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(`task_predecessor.confirmation.delete${selected.length > 1?'_plural':''}`) }}
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <PredecessorModal v-if="predecessorShow" :predecessor="predecessor" 
      :list="predecessors"
      :readOnly="predecessorReadOnly" 
      :show.sync="predecessorShow" 
      @success="predecessorSuccess" 
      :title="predecessorTitle"
      :projectId="projectId"
      :taskId="taskId"
      :taskName="taskName"
      :isTemplate="isTemplate"/>    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom } from '@/helpers';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import TaskLinkTypeCellRenderer from '@/components/Aggrid/CellRenderer/TaskLinkType';

export default {
  name: 'PredecessorList',
  components: {
    'ag-grid-vue': AgGridVue,
    PredecessorModal: () => import('@/components/modal/TaskPredecessorModal'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'taskLinkTypeCellRenderer': TaskLinkTypeCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    predecessors: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      default: null
    },
    taskName: {
      type: String,
      default: null
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      id: `PREDECESSOR_LIST_${strRandom(5)}`,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      predecessorShow: false,
      alertMsg: null,

      confirmDeleteShow: false,
      totalRecords: 0,

      predecessor: null
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(event) {
        event.api.sizeColumnsToFit();
      },
      getRowId: function(params) {
        return params.data.uuId;
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('task_predecessor.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: () => self.readOnly != true,
        lockVisible: true,
        pinned: 'left',
        flex: 1,
        minWidth: 150
      },
      {
        headerName: this.$t('task_predecessor.field.type'),
        field: 'type',
        cellRenderer: 'taskLinkTypeCellRenderer',
        maxWidth: 150,
        minWidth: 100
      },
      {
        headerName: this.$t('task_predecessor.field.lag'),
        field: 'lag',
        maxWidth: 100
        
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: false,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
  },
  mounted() {
  },
  created() {
    this.initPredecessorObj();
  },
  beforeDestroy() {
    this.gridApi = null;
  },
  watch: {
    predecessors: function(newValue) {
      if(this.gridApi != null && !this.gridApi.isDestroyed()) {
        this.rowData = newValue;
        this.gridApi.setGridOption('rowData', this.rowData);
        this.gridApi.refreshCells({ force: true });
      }
    },
    readOnly() {
      if(this.gridApi != null && !this.gridApi.isDestroyed()) {
        this.gridApi.redrawRows();
      }
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    showError() {
      return this.alertMsg != null;
    },
    predecessorTitle() {
      return this.predecessor && this.predecessor.uuId && this.predecessor.uuId.startsWith('PREDECESSOR_NEW')? 
        this.$t('task_predecessor.title_new') : this.$t('task_predecessor.title_edit');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('task_predecessor.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    predecessorReadOnly() {
      return 'SELECT' === this.mode || this.readOnly;
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = this.predecessors;
      
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    openDetail(id){
      this.predecessor = this.gridApi.getRowNode(id).data;
      this.predecessorShow = true;
      this.alertMsg = null;
    },
    predecessorEdit() {
      if (this.gridApi.getSelectedNodes().length > 0) {
        this.predecessor = this.gridApi.getSelectedNodes()[0].data;
        this.predecessorShow = true;
        this.alertMsg = null;
      }     
    },
    detailLinkLabel(params) {
      return `${params.data.name}`
    },
    predecessorSuccess({ /**msg,*/ predecessor }) {
      this.$emit('change', { old: this.predecessor, changed: predecessor });
      // this.alertMsg = msg;
    },
    predecessorAdd() {
      this.alertMsg = null;
      this.initPredecessorObj(true);
      this.predecessorShow = true;
    },
    predecessorDelete() {
      this.confirmDeleteShow = true;
    },
    confirmDeleteOk() {
      const toDeleteIds = cloneDeep(this.selected);
      this.$emit('delete', toDeleteIds);
      this.alertMsg = this.$t(`task_predecessor.grid.delete${toDeleteIds.length > 1? '_plural':''}`);
    },
    initPredecessorObj(reset = false) {
      if(reset || !this.predecessor) {
        this.predecessor = {
          uuId: null,
          name: null,
          type: null,
          lag: 0,
          tUuId: this.taskId,
          isNew: true
        }
      }
    }
  }
}


</script>

<style lang="scss">
  .predecessor-list {
    .predecessor-toolbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
        &:first-child {
          margin-left: 8px;
        }
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }

  #predecessorGrid .ag-root-wrapper {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
  }
  
  .predecessor-grid-height {
    height: 120px;
    min-height: 120px;
  }  
</style>