<template>
  <WidgetFrame>
    <template v-slot:title>
      {{ $t('permission.active_tasks') }}
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <ag-grid-vue class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="1000"
          :rowData="rowData"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
        </ag-grid-vue>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';
import { taskService } from '@/services';

export default {
  name: 'ProjectActiveTasksWidget',
  components: {
    'ag-grid-vue': AgGridVue,
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    linkCellRenderer: LinkCellRenderer,
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    project:      { type: Object, default: null },
    refresh:    { type: Boolean, default: false }
  },
  watch: {
    refresh(newValue, oldValue) {
      if (newValue) {
        this.buildData();
        this.$emit('refreshed');
      }
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>No active tasks</span>`;
    }
  },
  data: function() {
    return {
      loaded: false,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      activeTasks: []
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        self.gridColumnApi.autoSizeColumn('name', false);
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('active_task.grid.name'),
        field: 'name',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field) {
            self.$emit('editTask', {taskId: this.data.uuId, projectId: this.data.projectId, callback: null});
          },
        },
      },
      {
        headerName: this.$t('active_task.grid.path'),
        sortable: false,
        field: 'path',
        flex: 2,
      },
      {
        headerName: this.$t('active_task.grid.start'),
        field: 'startTime',
        sort: 'asc',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.end'),
        field: 'endTime',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.progress'),
        field: 'progress',
        minWidth: 50,
        maxWidth: 100,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      minWidth: 100,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
  },
  created() {
    this.buildData();
  },
  methods: {
    async buildData() {
      this.activeTasks = [];
      const self = this;
      const activeFilter = [
          "_and_",
          [
            ["PROJECT.TASK.progress", "gt", 0],
            ["PROJECT.TASK.progress", "lt", 1],
            ["PROJECT.TASK.taskType", "eq", 'Task']
          ]
        ];
        
        let tasksIds = await taskService.listProjectDashboard({ filter: activeFilter, limit_data: true, limit: 100 }, this.project.uuId).then(resp => {
          return resp.data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
        
        if (tasksIds.length !== 0) {
          const tasks = await taskService.listTasksDashboard(tasksIds.map(t => t.uuId)).then(response => {
            return response.data;
          }).catch(e => {
            // eslint-disable-next-line
            console.error(e);
          });
          
        tasks.forEach(function(task) {
          self.activeTasks.push({
            name: task.name,
            path: task.path,
            startTime: moment(task.startTime),
            endTime: moment(task.endTime),
            progress: task.progress,
            // Need these for the Edit Task modal
            uuId: task.uuId,
            projectId: self.project.uuId
          })
        });
        this.rowData = this.activeTasks;
      }
      else {
        this.rowData = [];
      }
      this.loaded = true;
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      this.rowData = this.activeTasks;
    },
  }
}
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>
